<template>
    <div :ref="'modelCard' + index" class="rsf-baby-compare-category-card bg-color-white gridbox mb2 overflow-hidden border-box">
        <div class="gridbox-element p3 bg-color-white">
            <p class="h3 helvetica-bold center" style="margin-bottom: 4px;">{{headline}}</p>
            <p class="h4 helvetica-regular center">{{subline}}</p>
            <img class="col-12 contain" :src="imgSrc"/>
            <div class="rsf-btn rsf-btn-secondary color-1 mx-auto h5" @click="expand()" style="height: 50px; white-space: break-spaces;" >{{ $t('rsf_compare_see_all_specs') }}</div>
        </div>
        <div ref="babyCompareCardTableWrapper" :class="`gridbox-element baby-compare-category-card-expand bg-color-white  ${tableExpanded ? 'active' : ''}`">
            <p class="h3 helvetica-bold cente pt3 center" style="margin-bottom: 4px;">{{headline}}</p>
            <p class="h4 helvetica-regular center mb3">{{subline}}</p>
            <slot></slot>
            <div class="justify-center mt3 gap2 px2 border-box flex">
                <a v-if="siteLanguage != 'cn'" class="rsf-btn rsf-btn-std bg-color-1 color-white no-shrink rsf-compare-table-bottom-btn h-fit-content open-sans-regular">{{ $t('rsf_build_with_myrefeer') }}</a>
                <a class="rsf-btn rsf-btn-tertiary color-1 center rsf-compare-table-bottom-btn open-sans-regular" style="white-space:break-spaces;">{{
                $t('rsf_compare_more_models') }}</a>
            </div>
            <div class="baby-compare-category-card-collape-btn pointer mx-auto mb4 mt3 bg-color-white" @click="collapse()">
                <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.384545 0.834162C0.0709215 1.14779 0.0709208 1.65627 0.384543 1.96989L8.41402 9.99937L0.383862 18.0295C0.0702395 18.3432 0.0702396 18.8516 0.383862 19.1653C0.697484 19.4789 1.20597 19.4789 1.51959 19.1653L9.54975 11.1351L17.5797 19.1651C17.8934 19.4787 18.4019 19.4787 18.7155 19.1651C19.0291 18.8515 19.0291 18.343 18.7155 18.0294L10.6855 9.99937L18.7148 1.97005C19.0284 1.65643 19.0284 1.14794 18.7148 0.834322C18.4012 0.520698 17.8927 0.520697 17.5791 0.83432L9.54975 8.86364L1.52027 0.834162C1.20665 0.520539 0.698167 0.520539 0.384545 0.834162Z" fill="#0E1317"/>
                </svg>
            </div>
        </div>
    </div>
</template>
  
<script>

export default {
    name: 'ModelsCard',
    data() {
        return {
            tableExpanded: false,
            tableHeight: 0,
        }
    },
    components: {
    },
    mounted(){
        console.log('get height of compare table ++++')
        console.log(this.$refs.babyCompareCardTableWrapper.offsetHeight)
        this.tableHeight = this.$refs.babyCompareCardTableWrapper.offsetHeight
        this.$refs.babyCompareCardTableWrapper.style.height = '0px';

    },
    methods: {
        expand(){
            // this.$refs.babyCompareCardTableWrapper.style.height = this.tableHeight + 'px'
            this.$refs.babyCompareCardTableWrapper.style.height = 'auto'
            this.tableExpanded = true;

            // let ref = 'modelCard' + this.index
            // var viewportOffset = this.$refs[ref].getBoundingClientRect();
            // var top = viewportOffset.top;
            // window.scrollTo(0, top);
        },
        collapse(){
            // let ref = 'modelCard' + this.index
            // var viewportOffset = this.$refs[ref].getBoundingClientRect();
            // var top = viewportOffset.top;
            // window.scrollTo(0, top);
            this.$refs.babyCompareCardTableWrapper.style.height = '0px'
            this.tableExpanded = false;
            
        }
    },
    props: {
        imgSrc: String,
        btnText: String,
        headline: String,
        subline: String,
        index: Number,
        siteLanguage: String
    },
}
</script>
  
<style>

</style>
  